
<template>
	<div>
		<div class="mb-3 row">
			<div class="col-12 col-md-9">
				<button class="btn btn-secondary rounded-pill" @click="openModalPrintInfosTiers"> {{ $t('action.print_infos_tiers') }} <font-awesome-icon :icon="['far', 'file-pdf']" class="ml-1" /></button>
				<button v-if="$cache.tiers.tiers_archive == 1" class="btn btn-secondary rounded-pill ml-4" @click="unarchive"> {{ $t('action.unarchive_the_tiers') }} <font-awesome-icon :icon="['far', 'trash-alt']" class="ml-1" /></button>
				<button v-else class="btn btn-secondary rounded-pill ml-4" @click="openModalArchiveTiers"> {{ $t('action.archive_the_tiers') }} <font-awesome-icon :icon="['far', 'trash-alt']" class="ml-1" /></button>
				<button class="btn btn-secondary rounded-pill ml-4" v-if="canInterrogatePappers" @click="interrogatePappers"> 
					<font-awesome-icon v-if="processing_pappers" :icon="['fal', 'spinner']" spin />
					{{ $t('action.interrogate_pappers') }} 
				</button>
				<button  v-if="hasClientArea && $cache.tiers.tiers_mail" class="btn btn-secondary rounded-pill ml-4" @click="triggerAutoLog">{{ $t('client_area.autolog_tier') }} {{ $cache.tiers.tiers_rs }}<font-awesome-icon :icon="['fal', 'user-tie']" class="ml-1" /></button>
				<template v-if="hasHubspotAccess">
					<div class="d-inline" v-if="$cache.tiers.hubspot_id || is_set_hubspot_id">
						<a class="btn btn-secondary rounded-pill ml-4" :href="'https://app.hubspot.com/contacts/8547080/company/' + $cache.tiers.hubspot_id" target="_blank">Hubspot</a>
						<button class="btn btn-secondary rounded-pill" @click.prevent="deleteLinkHubspot"><font-awesome-icon :icon="['fal', 'trash-alt']" /></button>
					</div>
					<div v-else class="d-inline">
						<div class="form-group">
							<label for="">Hubspot</label>
							<e-select
								track-by="id"
								label="label"
								v-model="searchValue"
								:options="hubspot_result"
								:placeholder="$t('global.rechercher')"
								:loading="loading_hubspot"
								:sortable="false"
								:internal-search="false"
								preserve-search
								@search-change="onSearch"
								ref="input"
							>
								<template slot="option" slot-scope="{ option }">{{ option.name }}</template>
								<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
								<template slot="noOptions">{{ $t('global.list_empty') }}</template>
							</e-select>
							<button class="btn btn-primary" @click.prevent="saveHubspot" v-if="searchValue">{{ $t('global.valider') }}</button>
						</div>
					</div>
				</template>
				<ModalConfirm
					ref="confirm_autolog"
					id="confirm_autolog"
					:icon="['fal', 'check']"
					:text_title="$t('client_area.autolog_modal_title')"
					:text_question="$t('client_area.autolog_modal_body')"
					:text_button_ok="$t('client_area.autolog_modal_confirm')"
					:text_button_cancel="$t('modal.general.annuler')"
					:callback_validation="askAutoLog"
					:callback_cancel="cancelAutoLog"
				/>
			</div>
		</div>

		<div class="row">
		    <div class="col-12 col-md-6">
		        <Coordonnees :edit="false" :tiers="$cache.tiers"></Coordonnees>
		    </div>
		    <div v-if="hasEntitiesAccess" class="col-12 col-md-6 mt-3 mt-md-0">
				<router-link class="nav-link col-3" active-class="active" :to="{ name: 'tiersContact'}" href="#">
					<h2>{{ $t('horse.menu_contacts') }}</h2>
       			</router-link>
		        <Contact :tiers_id="$cache.tiers.tiers_id" :dashboard="true" @addContactFromTiers="addContactFromTiers" v-if="showContacts"></Contact>
		    </div>

		</div>

		<div class="mt-4 row">
		    <div class="col-12 col-md-6">
				<QualificationFiche qualificationlink_type="tiers" :qualificationlink_fk="$cache.tiers.tiers_id.toString()" :dashboard="true" />
			</div>

			<div class="col-12 col-md-6">
				<h2>{{ $t('tiers.description') }}</h2>
				<div class="box">
					<textarea class="form-control" id="tiers_description" :placeholder="$t('tiers.description')" v-model="$cache.tiers.tiers_description" @keyup="showSaveButton"></textarea>
					<div class="col text-center mt-2" v-show="saveButton">
						<button class="btn btn-primary rounded-pill" @click="saveDescription">{{ $t('global.enregistrer') }} <font-awesome-icon :icon="['fal', 'save']" class="ml-1" /></button>
						<button class="btn btn-secondary ml-4 rounded-pill" @click="cancelDescription">{{ $t('global.annuler') }} <font-awesome-icon :icon="['fal', 'times']" class="ml-1"/></button>
					</div>
				</div>
		    </div>
		</div>

		<div v-if="hasFacturationAccess" class="mt-4 row">
			<div class="col-12">
				<router-link class="nav-link" active-class="active" :to="{ name: 'tiersInvoice'}" href="#">
					<h2>{{ $t('compta.impayes') }}</h2>
       			</router-link>
				<Invoice :tiers_id="$cache.tiers.tiers_id" :dashboard="true"></Invoice>
			</div>
		</div>

		<ModalPrintInfosTiers ref="modal_print_infos_tiers"/>

		<b-modal ref="modal_archive_tiers" hide-footer >
			<template v-slot:modal-title>
					{{ $t("action.confirmation_archive_tiers") }}
			</template>
			<div class="row justify-content-around">
				<b-button class="mt-3 btn-block rounded-pill col-5" variant="primary" @click="archive">{{ $t("global.oui") }}</b-button>
				<b-button class="mt-3 btn-block rounded-pill col-5" variant="primary" @click="closeModalArchiveTiers">{{ $t("global.non") }}</b-button>
			</div>
		</b-modal>
		<ContactForm ref="contact_form" from="tiers"/>
		<ModalTiersPappers ref="modal_pappers" @tiers="updateTiers($event)"/>
	</div>
</template>

<script type="text/javascript">
import Tiers from "@/mixins/Tiers.js"
import User from "@/mixins/User.js"
import _debounce from 'lodash/debounce'

export default {
    name: 'coordonneesTiers',
	mixins: [Tiers, User],

	data() {
		return {
			tiers: null,
			tiers_pappers: {},
			is_set_hubspot_id: false,
			saveButton: false,
			tiers_description: null,
			hubspot_selected: {},
			hubspot_result: [],
			loading_hubspot: false,
			debouncedSearch: _debounce(this.search, 300),
			searchValue: '',
			showContacts: true,
			processing_pappers: false,
		}
	},

	computed: {
		hasClientArea() {
			return this.$store.state.userAccess.hasDedicatedClientArea
		},
		hasEntitiesAccess() {
			return this.$store.state.userAccess.hasEntitiesAccess
		},
		hasFacturationAccess() {
			return this.$store.state.userAccess.hasFacturationAccess
		},
		hasHubspotAccess() {
			return this.$store.state.userAccess.hasHubspotAccess
		},
		canInterrogatePappers(){
			// Ne peut que si on a l'autorisation dans la license, le tiers est français et a un SIREN / SIRET
			const pappers = this.getConfig('api_config').find(conf => conf.apiconfig_type == "PAP")
			const t = this.$cache.tiers
			return pappers && t.tiers_country == 75
		}
	},

	/* Initialisation du tableau */
	mounted() {
		if(!this.$cache.tiers) {
			this.loadTiers()
		}
		else {
			this.tiers_description = this.$cache.tiers.tiers_description
		}
	},

	methods: {
		triggerAutoLog() {
			this.$refs.confirm_autolog.openModal()
		},
		cancelAutoLog() {
			this.$refs.confirm_autolog.closeModal()
		},
		async askAutoLog() {
			const url = await this.autologCaTier(this.$cache.tiers.tiers_mail)
			const win = window.open(url, '_blank')
  			win.focus()
		},
        async loadTiers() {
            this.tiers = await this.loadTier(this.$cache.tiers.tiers_id)
			this.$cache.tiers = this.tiers
        },
		archive() {
			this.archiveTiers(this.$cache.tiers.tiers_id)
			this.$refs.modal_archive_tiers.hide()
			this.successToast()
			this.$router.push({ name: 'tiersListe' })
		},
		async unarchive() {
			await this.unarchiveTiers(this.$cache.tiers.tiers_id)
			this.successToast()
			this.$cache.tiers.tiers_archive = 0
		},

		showSaveButton() {
			this.saveButton = true
		},

		saveDescription() {
			this.saveTiersDescription(this.$cache.tiers.tiers_id, this.$cache.tiers.tiers_description)
			.then(() => {
				this.saveButton = false
			})
		},

		cancelDescription() {
			this.$cache.tiers.tiers_description = this.tiers_description
			this.saveButton = false
		},

		openModalPrintInfosTiers() {
			this.$refs.modal_print_infos_tiers.openModal([this.$cache.tiers.tiers_id])
		},
		openModalArchiveTiers() {
			this.$refs.modal_archive_tiers.show()
		},
		interrogatePappers(){
			this.$refs.modal_pappers.openModal(this.$cache.tiers)
		},
		addContactFromTiers() {
			this.$refs.contact_form.addOrEditContact(this.$cache.tiers.tiers_id, null, null, this.$cache.tiers)
		},
		closeModalArchiveTiers(){
			this.$refs.modal_archive_tiers.hide()
		},
		loadContacts(){
			this.showContacts = false
			this.$nextTick(() => { this.showContacts = true })
		},
		onSearch(search_val) {
			this.debouncedSearch(search_val)
		},
		async search(search_val) {
			if(!search_val) {
				return
			}

			this.loading_hubspot = true

			const hubspot = this.searchFromHubspot(search_val)
				.then(res => {
					if(res.results) {

						this.hubspot_result = res.results.map(tiers => {
							return {
								name: tiers.properties.name,
								id: tiers.id,
							}
						})
					}
				})

			// Ajout des résultats au e-select
			await Promise.all([hubspot])

			this.loading_hubspot = false
		},

		async saveHubspot() {
			await this.saveHubspotTiers(this.$cache.tiers.tiers_id, this.searchValue.id)
			this.$cache.tiers.hubspot_id = this.searchValue.id
			this.is_set_hubspot_id = true
			this.searchValue = ''
		},

		async deleteLinkHubspot() {
			await this.saveHubspotTiers(this.$cache.tiers.tiers_id, null)
			this.$cache.tiers.hubspot_id = null
			this.is_set_hubspot_id = false
		},

		updateTiers(tiers){
			this.$cache.tiers.tiers_rs = tiers.tiers_rs
			this.$cache.tiers.tiers_address1 = tiers.tiers_address1
			this.$cache.tiers.tiers_address2 = tiers.tiers_address2
			this.$cache.tiers.tiers_postalcode = tiers.tiers_postalcode
			this.$cache.tiers.tiers_town = tiers.tiers_town
			this.$cache.tiers.tiers_statut_rcs = tiers.tiers_statut_rcs
			this.$cache.tiers.tiers_statut_rne = tiers.tiers_statut_rne
			this.$cache.tiers.tiers_date_creation = tiers.tiers_date_creation
			this.$cache.tiers.tiers_date_cessation = tiers.tiers_date_cessation
			this.$cache.tiers.tiers_siren = tiers.tiers_siren
			this.$cache.tiers.tiers_siret = tiers.tiers_siret
		}
    },

    components: {
		Coordonnees: () => import('@/components/Tiers/Coordonnees'),
		QualificationFiche: () => import('@/components/Qualification/QualificationFiche'),
		Contact: () => import('@/components/Contact/Contact'),
		AutoLogCa: () => import('@/components/Tiers/AutoLogCa'),
		Invoice: () => import('@/components/Tiers/Invoice'),
		ModalPrintInfosTiers : () => import('@/components/Tiers/ModalPrintInfosTiers'),
		ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm'),
		ContactForm: () => import('@/components/Contact/ContactForm'),
		ModalTiersPappers: () => import('@/components/Modals/ModalTiersPappers'),
    }
}
</script>